.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

.signature-example {
  background-color: mediumslateblue;
  width: 300px;
  height: 423px;
  border: black 5px solid;
  border-radius: 15px;
  background-size: 100% 100%;
}

.MuiCard-root-11 {
  background: transparent !important
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
